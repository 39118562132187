$image-width: 152px;
$image-height: 130px;
$image-margin: 30px;
$image-full-size: $image-width + $image-margin + $image-margin;
$title-gutter-width: 50px;
$title-black: #211e1f;

.header-card {
  position: relative;
  width: 100%;
  color: $title-black;
  min-height: 60px;

  &__title {
    margin-right: $image-full-size;
    width: calc(100% - #{$image-full-size});

    @media (max-width: 599px) {
      margin-right: 0;
      width: 100%;
    }
  }

  &__content {
    margin-top: 10px;
    margin-right: $image-full-size;
    width: calc(100% - #{$image-full-size});

    @media (max-width: 599px) {
      margin-right: 0;
      width: 100%;
    }
  }

  &__image {
    position: absolute;
    right: 0;
    top: 0;
    width: $image-width;
    margin: 0 $image-margin;

    @media (max-width: 599px) {
      display: none;
    }
  }
}
