$title-black: #211e1f;
$check-color: #054f95;

.sideinfo-status[--has='span'] {
  border: 10px dashed teal;
}
.form-control {
  & &__label {
    color: #627185;
    font-family: 'Gotham Bold', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    width:max-content;
    // text-transform: uppercase;
    transform: translate(0, 1.5px) scale(1);

    &--uncapped {
      text-transform: none;
    }
  }

  .euro-image-label{
    position: relative;
  }

  .euroImage{
    display: inline-block;
    padding: 3px;
    position: absolute;
    right: 16px;
    bottom: -3px;
  }

  & &__info-mark {
    font-size: 14px;
    margin-left: 2px;
  }

  &__tooltip {
    font-size: 12px;

    span {
      font-size: 12px;
    }
  }

  & &__input {
    color: $title-black;
    font-family: 'Gotham Light', sans-serif;
    font-weight: 400;
    font-size: 12pt;
    line-height: 14px;
    margin-top: 20px;
    height: 53px;
    width: 100%;
    padding: 3px 8px 0;
    border: 1px solid #DEDEDE;

    // &.with-type {
    //   padding-top: 10px;
    // }
    &--unpadded {
      padding: 3px 0 0;
    }
    &.kuna {
      width: 50%;
    }

  }

  & &__helper {
    color: $title-black;
    font-family: 'Gotham Light', sans-serif;
    font-weight: 400;
    font-size: 10px;
    &.date {
      overflow-x: visible;
      position: relative;
      right: 100px;
      width: 264px;
    }
  }

  & &__radio-group {
    color: $title-black;
    display: inline-block;
    font-family: 'Gotham Light', sans-serif;
    font-weight: 400;
    font-size: 14px;
    // margin-left: 10px;
    width: 100%;

    &--normal {
      margin-left: 14px;
    }

    span {
      font: inherit;
      vertical-align: baseline;
      color: #002554;
    }

    p {
      margin: 0;
    }

    ul {
      list-style: disc;
      padding-left: 20px;
    }
    a{
      color: #002554;
    }
  }

  .custom-status {
    $margin-right: 22px;
    max-width: 180px;
    width: calc((100% - #{2 * $margin-right}) / 3);
    height: 180px;
    flex-flow: column;
    position: relative;
    margin-top: 10px;
    margin-right: $margin-right;
    margin-left: 0;

    &:last-of-type {
      margin-right: 0;
    }

    .status-label {
      position: relative;
      // top: 100px;
      top: 152px;
    }

    &.student {
      background: url('../../../resources/images/Student.svg') no-repeat center;
      border: 1px solid #DEDEDE;
      opacity: 0.5;
    }

    &.pensioner {
      background: url('../../../resources/images/Umirovljenik.svg') no-repeat center;
      border: 1px solid #DEDEDE;
      opacity: 0.5;
    }

    &.employee {
      background: url('../../../resources/images/Zaposlenik.svg') no-repeat center;
      border: 1px solid #DEDEDE;
      opacity: 0.5;
    }

    &.checked {
      opacity:1;
      border: 2px solid #8092AA;
    }

  }
    .radio-input-like {
      position: relative;
      display: inline-flex;
      justify-content: space-between;
      position: relative;
      flex-direction: row-reverse;
      border: 1px solid #DEDEDE;
      width: 180px;
      height: 55px;
      margin-top: 10px;
      // margin-right: 40px;
      margin-right: 23px;
      margin-left: 0;
  
      &:last-of-type {
        margin-right: 0px;
        ;
      }
  
      .radio-input-like-label {
        padding-left: 15px;
      }
      &.checked {
        border: 2px solid #8092AA;
      }
      .form-control__political-radio{
          padding-right: 14px;
      }
    }
  & &__status-radio {
    position: absolute;
    right: 3px;
    top: 3px;
  }
  & &__square-radio {
    width: 40px;
    height: 30px;

    &--checked {
      color: $check-color;
    }

    svg {
      font-size: 16px;
      width: 100%
    }
  }

  &__accept-value {
    vertical-align: text-bottom;
  }

  & &__radio-label {
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
  }

  select {
    line-height: 36px;
  }
}

.explanation-modal {
  &__header {
    display: flex;
    flex-direction: row;
    position: relative;
    margin: 12px 12px 0 0;
    justify-content: end;
  }

  & &__title {
    padding-top: 0;

    h2 {
      font-family: 'Gotham Book';
      font-size: 18px;
      font-weight: 300;
      line-height: 20px;
      text-align: center;
    }
  }

  & &__content {
    font-family: 'Gotham Book';
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
  }
}

// datepicker hack for no margin under the month for some reason
.react-datepicker__current-month--hasYearDropdown {
  margin-bottom: 5px;
}

.react-datepicker__input-container {
  padding-top: 9px;
}

.phone-number {
  padding-left: 0 !important;
}

.with-prefix {
  display: flex;
  align-items: center;

  .prefix {
    margin: 22px 5px 0 0;
    font-size: 13px;
  }
}

.form-control {
  .form-control__label {
    &[class*="MuiFormLabel-filled-"]:not([class*="MuiFormLabel-error-"]) + .form-control__input {
      border: 2px solid #8092AA;
      padding: 2px 7px 0;

      .prefix {
        margin-bottom: 1px;
      }
    }
  }

  .form-control__input {

    outline: none;

    .prefix {
      align-self: center;
      margin-bottom: 2px;
    }

    &.date {
      > div:first-child {
        width: 100%;
      }

      .react-datepicker-wrapper {
        &, input, div {
          width: 100%;
        }
      }
    }
  }

}

.header-card__title{
  &.nopadding {
    margin: 0;
    width: auto;
  }
}

input.MuiInput-inputType-125 {
  height: auto;
}

.date-container {
  .date-picker {
    &.month {
      .form-control__input {
        margin-left: 2px;
        margin-right: 2px;
        width: calc(100% - 4px);
      }
    }

    .form-control__input {

      > div > div {
        height: auto !important;
      }

      input {
        height: auto !important;
        text-align: center;
      }
    }
  }
}
.presignup-sideinfo-container{
  background: url('../../../resources/images/BackgroundShadow.svg')!important;
  background-repeat: no-repeat !important;
}


@media screen and (min-width: 786px) {
  .no-negative {
    overflow-y: auto;
  }
}
@media screen and (max-width: 1062px) {
  .form-control {
    .custom-status {
      $height-small: 80px;
      width: 100%;
      max-width: 100%;
      height: $height-small;
      background-position: 10px bottom !important;
      background-size: auto #{$height-small - 10px} !important;
      padding: 1px;

      &.checked {
        border-width: 1px;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 1px solid #8092AA;
        }
      }

      .status-label {
        top: $height-small / 2;
        transform: translateY(-50%);
      }
  }

  .custom-is-slovenian, .custom-political {
    width: 100%;
  }
  
    }
    .selectors {
      width: 100%;
    }

    .no-negative{
      &.MuiGrid-spacing-xs-24-25 {
        width: auto!important;
        margin: initial!important;
      }
    }

  .presignup-container .presignup-form-container .presignup-form .terms p {
    margin-right: 0 !important;
  }

  .presignup-container .presignup-sideinfo-container .sideinfo {
    max-width: 400px;
    width: calc(100% - 20%) !important;
    .presignup-form__image {
      width: 100%;
    }

    .sideinfo-status .body {
      max-width: 300px;
      width: auto !important;
    }
  }
}
.card-application-form {
  padding: 71px 5px;
}
@media screen and (max-width: 786px) {
  .MuiGrid-container-1.presignup-container {
    overflow-x: hidden;
    flex-direction: column;
    background-image: none;

    .MuiGrid-grid-sm-6-49 {
      flex: 1;
      max-width: inherit;
    }

    .card-application-form{
      padding: 35px;
      .form-control {
          margin-top: 0;
        }

    } 

    .presignup-sideinfo-container {
      background: url('../../../resources/images/BackgroundShadow.svg') no-repeat top center;
      background-size: cover!important;
      padding-right: 0px!important;
    }
  }


  .political-modal {
    max-width: 95%;

    img {
      max-width: 100%;
    }
  }
}
.modal-date-picker{
  label{
    width: max-content !important;
    top: -19px;
    position: absolute !important;
  }
}
@media screen and (max-width: 460px) {
  .form-control {

    .radio-input-like {

      width: 100%;
      &-label {
        left: 10px;
      }
    }

  }

}
.date-picker, .custom-selector {
  .form-control__input {
    padding-right: 0 !important;

    div > div {
      height: 32px;
      line-height: 36px;
      padding-right: 16px;
      width: calc(100% - 16px);

      &:focus {
        background: transparent !important;
      }
    }

    .MuiSelect-icon-138 {
      right: -2px;
    }
  }
}

.day-selector-item,
.year-selecor-item {
  flex-direction: row-reverse;
}

.day-selector-item,
.month-selector-item,
.year-selector-item,
.custom-selector-item {
  height: 12px !important;
  font-size: 14px !important;
}
