.MuiFormHelperText-root-127.MuiFormHelperText-error-128
{
  color: #f44336;
}

.headerGrid {
  padding: 12px;
  box-shadow: 0 0.3rem 1.1rem 0 rgba(0,0,0,.12);
  position: absolute;
  width: 100%;
  background: white;

  .headerGridItem1 {
    margin-left: 15%;
  }

  .headerLogo {
    max-height: 30px;
  }
}
.page-content-pre-signup{
  padding-top: 60px;
}
.presignup-container {
  background-size: 50% 100%;
  background-repeat: no-repeat;
  background-position-x: right;
  .presignup-form-container {
    .presignup-form {
      margin-bottom: 183px;
      max-width: 735px;
      padding: 75px;
      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type=number] {
        -moz-appearance: textfield;
      }

      font-family: 'Gotham Light', sans-serif;


     button:not(.react-datepicker__navigation) {
        background: #FFE250 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #EBEBEB;
        opacity: 1;
        text-align: center;
        font: 14px/17px Gotham Bold;
        letter-spacing: 0.54px;
        color: #002554;
        text-transform: uppercase;
        height: 53px;
        opacity: 1;
        i {
          border: solid black;
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 3px;
          position: relative;
          left: 45px;
        }

        .right {
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }
      }

      &__title {
        text-align: left;
        font: Light 28px/46px Gotham;
        letter-spacing: 0;
        color: #002554;
        opacity: 1;
        font-weight: normal;

      }

      &__subtitle {
        font-family: 'Gotham Light', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.625;
      }

      &__image {
        transform: scale(0.5);
        transform-origin: top left;
      }

      &__content {
        min-height: 100px;
        margin-bottom: 12px;
      }

      &__help-title {
        margin: 0;
        font-family: 'Gotham Book', sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
      }

      &__help-description {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
      }

      &__help-image {
        transform: scale(0.5);
        transform-origin: top left;
      }

      & &__button {
        border-radius: 0;
        box-shadow: none;
        font-family: 'Gotham Bold', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        padding: 20px 28px;

        color: #262626;
        background: #ffe250;

        &:hover {
          color: #262626;
          background: #f9ea9f;
        }
      }

      &__download-image {
        display: inline-block;
        vertical-align: middle;
        margin: 7px 24px 7px 0;
      }

      .vertical-align-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .accept-value {
        svg {
          vertical-align: text-bottom;
        }
      }


      .no-margin p {
        margin: 0px 0px 0px 0px;
      }

      .terms p {
        // margin-right: 29%;
        text-align: justify;
      }

      .terms {
        .form-control__helper {
          margin-top: 10px;
          font-size: 16px;
        }
      }
    }
  }


  .presignup-sideinfo-container {
    .sideinfo {
          margin: 0px auto;
          margin-top: 95px;
          max-width: 480px;
          width: 100%;

      .sideinfo-title {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0;
        // margin-top: 20px;
        color: #627185;
        opacity: 1;

        .body {
          text-align: center;
          font-size: 20px;
          letter-spacing: 0;
          color: #627185;
          line-height: 32px;
          opacity: 1;
          margin-top: 5px;
          font-weight: 200;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .link {
          margin-top: 10px;
          text-align: center;
          font: 14px Gotham Book;
          letter-spacing: 0;
          text-decoration: none;
          color: inherit;
          a {
            color: inherit;
            text-decoration: none;
          }
          opacity: 1;
        }
      }

      .calculator-modal-button{
        background: #CDEBFC 0% 0% no-repeat padding-box!important;
        -webkit-box-shadow: 0px 3px 6px #ebebeb!important;
        box-shadow: 0px 3px 6px #ebebeb!important;
        opacity: 1!important;
        text-align: center!important;
        font: 14px/17px Gotham Bold!important;
        letter-spacing: 0.54px!important;
        color: #002554!important;
        text-transform: uppercase!important;
        height: 53px!important;
        width: 100%!important;
        max-width: 280px;
        opacity: 1!important;
        margin: 26px 0px!important;
      }

      .calculator-modal-button-finished{
        background-color: transparent;
        background-repeat: no-repeat;
        border: 1px solid #DEDEDE;
        -webkit-box-shadow: 0px 3px 6px #ebebeb;
        box-shadow: 0px 3px 6px #ebebeb;
        opacity: 1;
        text-align: center;
        font: 14px/17px Gotham Bold;
        letter-spacing: 0.54px;
        text-transform: uppercase;
        height: 53px;
        width: 70%;
        opacity: 1;
        margin: 26px 0px;
       color: #666666;
}

      .calculator-modal-button-disabled{
        background: #CDEBFC 0% 0% no-repeat padding-box;
        -webkit-box-shadow: 0px 3px 6px #ebebeb;
        box-shadow: 0px 3px 6px #ebebeb;
        opacity: 1;
        text-align: center;
        font: 14px/17px Gotham Bold;
        letter-spacing: 0.54px;
        color: #002554;
        text-transform: uppercase;
        height: 53px;
        width: 70%;
        opacity: 1;
        margin: 26px 0px;
      }

      .modal-arrow{
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        position: relative;
        left: 45px;
        transform: rotate(-45deg);
      }

      .sideinfo-picture {
        width: 416px;
        height: 416px;
        background-image: url('../../../resources/images/sidebar_default.svg');
        background-repeat: no-repeat;
      }

      .sideinfo-status {
        text-align: center;
        font: 16px/22px Gotham Book;
        letter-spacing: 2.08px;
        color: #6B798C;
        text-transform: uppercase;
        opacity: 1;
        height: 300px;
        .body {
          text-align: center;
          font: 14px/24px Gotham Book;
          letter-spacing: 0;
          color: #6B798C;
          opacity: 1;
          text-transform: none;
          width: 300px;
          margin: auto
        }
      }

      .presignup-sideinfo {
        padding: 40px;
      }

    }
  }
}

.form-fail-polygon {
  width: 625px;
  height: 660px;
  background-image: url('../../../resources/images/no_bg.svg');
  background-repeat: no-repeat;
  opacity: 1;
  position: relative;
  margin: 20px;
  .fail-icon {
    position: absolute;
    right: 40px;
    top: 61px;
  }
  .fail-content {
    color: white;
    width: 460px;
    padding-top: 16%;
    padding-left: 17%;

    .title {
      font: 28px/36px Gotham Light;
      letter-spacing: 0;
      color: #FFFFFF;
      opacity: 1;
    }

    .body {
      text-align: left;
      font: 14px/24px Gotham Book;
      letter-spacing: 0;
      color: #FFFFFF;

      a {
        color: #FFFFFF
      }
    }

    .link {
      text-align: left;
      margin-top: 77px;
      text-decoration: underline;
      font: 14px/24px Gotham Book;
      letter-spacing: 0;
      color: #FFFFFF;
      opacity: 1;
      div > svg {
        margin-right: 20px;
        vertical-align: top;
      }

      a {
        color: #FFFFFF;
      }
    }
  }
}

.form-success-polygon {
  width: 625px;
  height: 660px;
  background-image: url('../../../resources/images/yes_bg.svg');
  background-repeat: no-repeat;
  opacity: 1;
  position: relative;
  margin: 20px;

  .mobile-image {
    position: absolute;
    left: 470px;
    top: 215px;
    z-index: 100;
  }
  .success-icon {
    position: absolute;
    right: 40px;
    top: 61px;
  }

  .success-content {
    padding-top: 16%;
    padding-left: 17%;
    width: 450px;
    color: white;
    position: relative;

    .store-link-container {
      display: flex;
      flex-direction: row;

      .store-link {
        margin-right: 10px;
        height: 60px;
        width: 170px;
        a > img {
          width: 100%;
        }
      }
    }

    .intltext {

      > p {
        margin-block-start: 0;
        margin-block-end: 0;
      }

      &.header {
        text-align: left;
        font: 28px/36px Gotham Light;
        letter-spacing: 0;
        color: #FFFFFF;
      }
      &.pre-link {
        text-align: left;
        font: 14px/24px Gotham Book;
        letter-spacing: 0;
        color: #FFFFFF;
        margin-bottom: -20px;
      }
      &.footer {
        margin-top: 20px;
      }
    }
  }
}

.image-responisve{
  width: 100%!important;
  height: auto!important;
}

.link-modal{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #6B798C;
}

.linkDiv{
  padding-bottom: 25px!important;
}

.no-wrap{
  flex-wrap: nowrap!important;
}

.modalTitle{
  font-family: 'Gotham Book', serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 27px !important;
  color: #002554 !important;
}

.closeDiv{
  width: 54px !important;
}

.calculator-button{
  background: #FFE250 0% 0% no-repeat padding-box !important;
  -webkit-box-shadow: 0px 3px 6px #ebebeb !important;
  box-shadow: 0px 3px 6px #ebebeb !important;
  opacity: 1 !important;
  text-align: center !important;
  font: 14px/17px Gotham Bold !important;
  letter-spacing: 0.54px !important;
  color: #002554 !important;
  text-transform: uppercase !important;
  height: 53px !important;
  max-width: 281px;
  width: 100% !important;
  opacity: 1 !important;
  margin-bottom: 12px;
  cursor: pointer!important;
  border: none!important;
}

.calculator-button:active {
  background: #e5e5e5 !important;
  -webkit-box-shadow: inset 0px 0px 5px #c1c1c1 !important;
  -moz-box-shadow: inset 0px 0px 5px #c1c1c1 !important;
  box-shadow: inset 0px 0px 5px #c1c1c1 !important;
  outline: none !important;
}

.modal-button-arrow{
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  position: relative;
  left: 45px;
  transform: rotate(-45deg);
}

.presignup-form__content_modal_calc{
  div {
    width: 100%;
    margin: initial;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 420px;
    margin: 0px auto;

  }
    .form-control{
      &__label {
          width: initial;
          position: relative;
        }
      &__input {
          margin-top: 6px;
        }
    } 

} 

.date-container{
  display: flex!important;
  flex-wrap: wrap!important;
  flex-direction: row!important;
  justify-content: center!important;
  align-items: baseline!important;
  max-width: 100%!important;
}

 .buttonModalDiv{
   padding: 12px;

 }
@media screen and (max-width: 786px) {
  .presignup-form{
    padding: 70px 25px !important;
  }
  .date-container{
    padding: 0 21px;
  }
}

.calculator-modal {
  position:absolute;
  top: 52%;
  left: 50%;
  overflow:auto;
  height:100%;
  max-width: 550px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  transform: translate(-50%, -50%);
  outline: none;
  .closeDiv {
    width: 54px !important;
    /* height: 49px !important; */
  }

  .closeModal {
    position: absolute;
    width: 50px;
    right: -3px;
    cursor: pointer;
    overflow: hidden;



  }

  .modal-text {
    text-align: center;
    font: 14px Gotham Book;
    letter-spacing: 0;
    color: #002554;
    padding: 0 30px 30px;
    // React Markdown Paragraph auto fill these
    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }

    a {
      color: #002554;
      font-weight: 600;
    }
  }
}


.political-modal {
  top: 50%;
  left: 50%;
  position: absolute;
  background-color: white;
  width: 490px;
  display: flex;
  flex-direction: column;
  transform: translate(-50%, -50%);
  outline: none;

  .close {
    position: absolute;
    display: inline-block;
    width: 22px;
    height: 22px;
    right: -3px;
    cursor: pointer;
    margin: 10px 10px -33px;
    overflow: hidden;

    overflow: hidden;
    &::before, &::after {
      content: '';
      position: absolute;
      height: 2px;
      margin-top: -2px;
      width: 100%;
      top: 50%;
      left: 0;
      background: #002554;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }

  .modal-text {
    text-align: center;
    font: 14px Gotham Book;
    letter-spacing: 0;
    margin-top: -35px;
    color: #002554;
    padding: 0 30px 30px;
    // React Markdown Paragraph auto fill these
    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }

    a {
      color: #002554;
      font-weight: 600;
    }
  }
}
