@font-face {
  font-family: 'Gotham Light';
  src: url('../fonts/gotham-light.woff2') format('woff2'),
    url('../fonts/gotham-light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Book';
  src: url('../fonts/gotham-book.woff2') format('woff2'),
    url('../fonts/gotham-book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Bold';
  src: url('../fonts/gotham-bold.woff2') format('woff2'),
    url('../fonts/gotham-bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
