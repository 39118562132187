

.telephoneNumberSpan{
  color:  lightskyblue!important;
}

.amountInfo{
  margin: 26px 0px;
  text-align: left;
  width: 396px;
  height: 98px;
  font-family: 'Gotham Light';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #627185;

}
.numberSpan{
  font: 20px Gotham Light;
}

.amountTextRejected{
  font-size: 14px;
  line-height: 22px;
  height: 152px;
  letter-spacing: 0.13em;
  margin: 26px 0px;
  text-align: left;
  padding: 25px 25px 45px;
  background: #FFFFFF;
  box-shadow: 10px 5px 12px rgba(127, 144, 158,0.1);
  border-radius: 0 0 50px 0;
  span{
    width: 437px;
    height: 50px;
    font-family: 'Gotham Light';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.13em;


    color: #627185;
  }
}

@media screen and (max-width: 786px) {
  .amountInfo{
    margin: 65px 0;
    width: auto;
  }

  .amountTextRejected{
    padding: 10px 25px 45px;
  }
}
