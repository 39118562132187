.card-application-form {
  .text-field {
    margin-top: 16px;
    width: 100%;

    &.normalize {
      input {
        height: auto !important;
      }
    }
  }

  .subheading {
    margin-top: 16px;
  }

  .form-control {
    display: block;
    margin-top: 16px;

    &.inline {
      margin: -18px 0 0;
    }
  }

  .radio-group {
    display: inline-block;
  }

  .block {
    display: block;
  }

  .progress {
    position: absolute;
    color: #006400;
  }

  .date-picker, .custom-selector {
    width: 100%;
  }
}
