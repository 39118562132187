@import 'fonts';
//@import 'critical'; // import this to test with production style overrides

/* Variables */
$vin-blue: #5bb7db;
$vin-green: #60b044;
$vin-red: #f00;

$title-black: #211e1f;
$information-bar-background: #ee1;

/* Counter Production Styles */
html {
  font-size: 100%; // to counter production style overrides
}

/* Styles */
body {
  //font: 14px 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font: 14px 'Gotham Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.4em;
  color: #4d4d4d;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-weight: 400;
  background: #fff; // url('https://assets.atlasobscura.com/media/W1siZiIsInVwbG9hZHMvcGxhY2VfaW1hZ2VzLzA0MzRjZGI4MTJlNjdkMzBmNV93aW5kb3dzX3hwX2JsaXNzLXdpZGUuanBnIl0sWyJwIiwidGh1bWIiLCJ4MzkwPiJdLFsicCIsImNvbnZlcnQiLCItcXVhbGl0eSA4MSAtYXV0by1vcmllbnQiXV0/windows_xp_bliss-wide.jpg') no-repeat top center;
  //background-size: cover;
}

.content {
  min-width: 230px;
  max-width: 88%;
  width: calc(100% - 32px);
  margin: 16px auto;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
}

td {
  padding: 12px;
}

h2 {
  color: $vin-blue;
}

.savings { color: $vin-green; }
.loss { color: $vin-red; }
input.small { width: 46px; }
td.fuel-savings-label { width: 175px; }

* {
  box-sizing: border-box;
}

.page-information-bar {
  width: 100%;
  color: $title-black;
  background-color: $information-bar-background;
  border-bottom: 1px solid $title-black;
  padding: 0 10px;
}

.page-content {
  margin: 40px auto 0;
  padding: 5px;
  max-width: 730px;
}


